import React, { useState } from 'react';
import styled from 'styled-components';
import Material from './Material';

const materials = {
  // ...Semester 1
  "Manajemen Survey dan Data": [
    { title: "System of City", pdfPath: "/pdf/1/msd/soc.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/1/msd/materi2.pdf" },
  ],
  "Geo Ekologi": [
    { title: "Materi 1", pdfPath: "/pdf/1/ge/materi1.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/1/ge/materi2.pdf" },
  ],
  "Teknik Pemetaan": [
    { title: "Materi 1", pdfPath: "/pdf/semester1/fisika/materi1.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/semester1/fisika/materi2.pdf" },
  ],
  "Pengantar Geografi": [
    { title: "Materi 1", pdfPath: "/pdf/semester1/fisika/materi1.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/semester1/fisika/materi2.pdf" },
  ],
  "Bahasa Indonesia": [
    { title: "Materi 1", pdfPath: "/pdf/semester1/fisika/materi1.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/semester1/fisika/materi2.pdf" },
  ],
  "Pendidikan Agama Islam": [
    { title: "Materi 1", pdfPath: "/pdf/semester1/fisika/materi1.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/semester1/fisika/materi2.pdf" },
  ],
  "Pengantar PWK": [
    { title: "Materi 1", pdfPath: "/pdf/semester1/fisika/materi1.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/semester1/fisika/materi2.pdf" },
  ],
  "Matematika Perencanaan": [
    { title: "Materi 1", pdfPath: "/pdf/semester1/fisika/materi1.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/semester1/fisika/materi2.pdf" },
  ],
  "Teknik Presentasi": [
    { title: "Materi 1", pdfPath: "/pdf/semester1/fisika/materi1.pdf" },
    { title: "Materi 2", pdfPath: "/pdf/semester1/fisika/materi2.pdf" },
  ],

   // ...Semester 2

   // ...Semester 3

   // ...Semester 4
      // Senin
   "Metode dan Teknik Perencanaan Perumahan Permukiman": [
    { "title": "01_PENGANTAR PERKULIAHAN.pptx", "pdfPath": "/pdf/4/Mtppp/MTPPP_01_PENGANTAR PERKULIAHAN.pptx.pdf" },
    { "title": "02_Kerangka Analisis Perencanaan Perumahan Permukiman.pdf", "pdfPath": "/pdf/4/Mtppp/MTPPP_02_Kerangka Analisis Perencanaan Perumahan Permukiman.pdf" },
    { "title": "03_DELINEASI, ISU STRATEGIS, TUJUAN  SASARAN [Autosaved].pptx", "pdfPath": "/pdf/4/Mtppp/MTPPP_03_DELINEASI, ISU STRATEGIS, TUJUAN  SASARAN [Autosaved].pptx.pdf" },
    { "title": "05_Analisis HNA 2012.pdf", "pdfPath": "/pdf/4/Mtppp/MTPPP_05_Analisis HNA 2012.pdf" },
    { "title": "05_Analisis HNA 2023B.ppt.pdf", "pdfPath": "/pdf/4/Mtppp/MTPPP_05_Analisis HNA 2023B.ppt.pdf" },
    { "title": "06_Defining Planning Zone or Neighbourhood Unit.pptx", "pdfPath": "/pdf/4/Mtppp/MTPPP_06_Defining Planning Zone or Neighbourhood Unit.pptx.pdf" },
    { "title": "06_Perumusan Blok_Unit Lingkungan.pdf", "pdfPath": "/pdf/4/Mtppp/MTPPP_06_Perumusan Blok_Unit Lingkungan.pdf" },
    { "title": "07_Materi Konsep Perencanaan dan Perancangan rev.pptx", "pdfPath": "/pdf/4/Mtppp/MTPPP_07_Materi Konsep Perencanaan dan Perancangan rev.pptx.pdf" },
    { "title": "08_Analisis Perancangan & UDGL.pptx.pdf", "pdfPath": "/pdf/4/Mtppp/MTPPP_08_Analisis Perancangan & UDGL.pptx.pdf" },
    { "title": "10_Economic and Financial Analysis of Housing and Settlement.pptx.pdf", "pdfPath": "/pdf/4/Mtppp/MTPPP_10_Economic and Financial Analysis of Housing and Settlement.pptx.pdf" },
    { "title": "16_2023A_Laporan Kelompok 6.pdf", "pdfPath": "/pdf/4/Mtppp/MTPPP_16_2023A_Laporan Kelompok 6.pdf" },
    { "title": "Progress Report Sadang Serang (28.11)_paparan.pptx.pdf", "pdfPath": "/pdf/4/Mtppp/MTPPP_Progress Report Sadang Serang (28.11)_paparan.pptx.pdf" }
    ],

   "Perancangan Kota": [
    { title: "02_Sejarah dan Paradigma Kota", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_02_Sejarah dan Paradigma Kota.pdf" },
    { title: "03_Teori Basis Perancangan Kota (Teori dari Eropa)", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_03_Teori Basis Perancangan Kota (Teori dari Eropa).pdf" },
    { title: "04_Morfologi Bentuk dan Struktur Kota", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_04_Morfologi Bentuk dan Struktur Kota.pdf" },
    { title: "06_Konsep dan Wujud Kota", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_06_Konsep dan Wujud Kota.pdf" },
    { title: "07_Bentuk dan Estetika Rancang Kota", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_07_Bentuk dan Estetika Rancang Kota.pdf" },
    { title: "07-01_MEMAHAMI KONTEKS dalam RANCAN KOTA 1 April 24_Versi INDO", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_07_Bentuk dan Estetika Rancang Kota/1-MEMAHAMI  KONTEKS dalam RANCAN KOTA 1 April 24_Versi INDO.pdf" },
    { title: "07-02_The Morphological Dimension 2_Versi INDO18Apr24", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_07_Bentuk dan Estetika Rancang Kota/2-The Morphological Dimension 2_Versi INDO18Apr24.pdf" },
    { title: "07-03_Materi UD demensi Perseptual - Copy_Versi INDO", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_07_Bentuk dan Estetika Rancang Kota/3-Materi UD demensi Perseptual - Copy_Versi INDO.pdf" },
    { title: "07-04_FUNCTIONAL DEMENSION OF URBAN DESIGN_Versi INDO", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_07_Bentuk dan Estetika Rancang Kota/4-FUNCTIONAL DEMENSION OF URBAN DESIGN_Versi INDO.pdf" },
    { title: "07-05_UD SOCIAL DEMENSION_Versi INDO", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_07_Bentuk dan Estetika Rancang Kota/5-UD SOCIAL  DEMENSION_Versi INDO.pdf" },
    { title: "07-06_VISUAL DEMENSION OF UD", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_07_Bentuk dan Estetika Rancang Kota/6-VISUAL DEMENSION OF UD.pdf" },
    { title: "09_Elemen Perancangan Kota", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_09_Elemen Perancangan Kota.pdf" },
    { title: "12_Teori Perancangan Kota", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_12_Teori Perancangan Kota.pdf" },
    { title: "14_Public Space", pdfPath: "/pdf/4/Perangkot/Perangkot_KelasABC_14_Public Space.pdf" }
  ],
      //Rabu
  "Pembiayaan Pembangunan Perumahan": [
    { "title": "00_AGENDA KEGIATAN MK PEMBEAYAAN PEMBANGUNAN PERUMAHAN 2024", "pdfPath": "/pdf/4/P3/00_AGENDA KEGIATAN MK PEMBEAYAAN PEMBANGUNAN PERUMAHAN 2024.pdf" },
    { "title": "00_Rencana Pembelajaran Semester (RPS)", "pdfPath": "/pdf/4/P3/00_Rencana Pembelajaran Semester (RPS).pdf" },
    { "title": "02_Karakteristik Pembiayaan Perumahan 2024", "pdfPath": "/pdf/4/P3/02_Karakteristik Pembiayaan Perumahan 2024.pdf" },
    { "title": "03_PEMBIAYAAN PERUMAHAN FORMAL DAN PERAN PERBANKAN 2024", "pdfPath": "/pdf/4/P3/03_PEMBIAYAAN PERUMAHAN FORMAL DAN PERAN PERBANKAN 2024.pdf" },
    { "title": "04_SISTEM PEMBIAYAAN PERUMAHAN DAN PERAN PEMERINTAH 2024", "pdfPath": "/pdf/4/P3/04_SISTEM PEMBIAYAAN PERUMAHAN DAN PERAN PEMERINTAH 2024.pdf" },
    { "title": "06_KOMPONEN PEMBIAYAAN PEMBANGUNAN PERUMAHAN_2023", "pdfPath": "/pdf/4/P3/06_KOMPONEN PEMBIAYAAN PEMBANGUNAN PERUMAHAN_2023.pdf" },
    { "title": "06_KOMPONEN PEMBIAYAAN PEMBANGUNAN PERUMAHAN_2024", "pdfPath": "/pdf/4/P3/06_KOMPONEN PEMBIAYAAN PEMBANGUNAN PERUMAHAN_2024.pdf" },
    { "title": "07_ANALISIS KELAYAKAN PASAR (UTS)", "pdfPath": "/pdf/4/P3/07_ANALISIS KELAYAKAN PASAR (UTS).pdf" },
    { "title": "08_EKONOMI TEKNIK DALAM PEMBIAYAAN PERUMAHAN_2024", "pdfPath": "/pdf/4/P3/08_EKONOMI TEKNIK DALAM PEMBIAYAAN PERUMAHAN_2024.pdf" },
    { "title": "08_Tugas Ekonomi Teknik", "pdfPath": "/pdf/4/P3/08_Tugas Ekonomi Teknik.pdf" },
    { "title": "09_Karakteristik, Konsep, dan Strategi Pembiayaan Informal", "pdfPath": "/pdf/4/P3/09_Karakteristik, Konsep, dan Strategi Pembiayaan Informal.pdf" },
    { "title": "10_Pembiayaan Mikro Untuk MBR_2024 (UAS)", "pdfPath": "/pdf/4/P3/10_Pembiayaan Mikro Untuk MBR_2024 (UAS).pdf" },
    { "title": "TOR TUGAS 2 Pembiayaan Perumahan 2024", "pdfPath": "/pdf/4/P3/TOR TUGAS 2 Pembiayaan Perumahan 2024.pdf" }
  ],

  "Hukum Administrasi Perencanaan": [
    { "title": "01_Asas, Tujuan, Hirarki Rencana di Indonesia", "pdfPath": "/pdf/4/HAP/01_Asas, Tujuan, Hirarki Rencana di Indonesia.pdf" },
    { "title": "01_Pengantar hukum", "pdfPath": "/pdf/4/HAP/01_Pengantar hukum.pdf" },
    { "title": "02_HUKUM DAN ADMINISTRASI PERENCANAAN", "pdfPath": "/pdf/4/HAP/02_HUKUM DAN ADMINISTRASI PERENCANAAN.pdf" },
    { "title": "02_Hukum penataan ruang 1", "pdfPath": "/pdf/4/HAP/02_Hukum penataan ruang 1.pdf" },
    { "title": "03_1Hukum penataan ruang 2", "pdfPath": "/pdf/4/HAP/03_1Hukum penataan ruang 2.pdf" },
    { "title": "03_2Hukum penataan ruang 2", "pdfPath": "/pdf/4/HAP/03_2Hukum penataan ruang 2.pdf" },
    { "title": "03_Dasar hukum perencanaan", "pdfPath": "/pdf/4/HAP/03_Dasar hukum perencanaan.pdf" },
    { "title": "04_0Hukum pelayanan publik (UTS)", "pdfPath": "/pdf/4/HAP/04_0Hukum pelayanan publik (UTS).pdf" },
    { "title": "04_1Hukum perencanaan pembangunan", "pdfPath": "/pdf/4/HAP/04_1Hukum perencanaan pembangunan.pdf" },
    { "title": "04_2Hukum perencanaan pembangunan", "pdfPath": "/pdf/4/HAP/04_2Hukum perencanaan pembangunan.pdf" },
    { "title": "05_Hukum KLHS", "pdfPath": "/pdf/4/HAP/05_Hukum KLHS.pdf" },
    { "title": "05_Hukum perumahan dan kawasan permukiman;", "pdfPath": "/pdf/4/HAP/05_Hukum perumahan dan kawasan permukiman;.pdf" },
    { "title": "06_Hukum agraria_1TANAH HUKUM HAK PENDAFTARAN REFORMA Kapita Selekta", "pdfPath": "/pdf/4/HAP/06_Hukum agraria_1TANAH HUKUM HAK PENDAFTARAN REFORMA Kapita Selekta.pdf" },
    { "title": "06_Hukum agraria_2UUPA dan Pembaruan akibat UUCK", "pdfPath": "/pdf/4/HAP/06_Hukum agraria_2UUPA dan Pembaruan akibat UUCK.pdf" },
    { "title": "06_Hukum agraria_3MODUL 2021 Kapita Selekta Pertanahan", "pdfPath": "/pdf/4/HAP/06_Hukum agraria_3MODUL 2021 Kapita Selekta Pertanahan.pdf" },
    { "title": "06_Pengadaan tanah untuk kepentingan umum", "pdfPath": "/pdf/4/HAP/06_Pengadaan tanah untuk kepentingan umum.pdf" },
    { "title": "07_ARAH KEBIJAKAN PERENCANAAN DI INDONESIA", "pdfPath": "/pdf/4/HAP/07_ARAH KEBIJAKAN PERENCANAAN DI INDONESIA.pdf" },
    { "title": "07_Hukum peralihan hak atas tanah (UAS)", "pdfPath": "/pdf/4/HAP/07_Hukum peralihan hak atas tanah (UAS).pdf" }
  ],

  //Kamis
  "Perencanaan Wilayah": [
    { "title": "00_PENGAMPU Kontrak Perwil JADWAL MENGAJAR PARALEL 2024", "pdfPath": "/pdf/4/Perwil/00_PENGAMPU Kontrak Perwil JADWAL MENGAJAR PARALEL 2024.pdf" },
    { "title": "01_Pengantar Konsep Perencanaan Wilayah", "pdfPath": "/pdf/4/Perwil/01_Pengantar Konsep Perencanaan Wilayah.pdf" },
    { "title": "02_20240222 1 PARADIGMA PERENCANAAN WILAYAH", "pdfPath": "/pdf/4/Perwil/02_20240222 1 PARADIGMA PERENCANAAN WILAYAH.pdf" },
    { "title": "03_Perwil 23B_Indusrialisasi Wilayah", "pdfPath": "/pdf/4/Perwil/03_Perwil 23B_Indusrialisasi Wilayah.pdf" },
    { "title": "04_Latihan Perwil 23B_Sistem Perkotaan (Exsel)", "pdfPath": "https://docs.google.com/spreadsheets/d/1iovagKXos0sK8HgZJlIiLOlAmEKS45lp/edit?usp=sharing&ouid=103772930547021544337&rtpof=true&sd=true" }, //pdf/4/Perwil/04_Latihan Perwil 23B_Sistem Perkotaan.xlsx
    { "title": "04_Perwil_23B_System of Cities", "pdfPath": "/pdf/4/Perwil/04_Perwil_23B_System of Cities.pdf" },
    { "title": "05_20240322 GROWTH POLES THEORY dan Struktur Wilayah", "pdfPath": "/pdf/4/Perwil/05_20240322 GROWTH POLES THEORY dan Struktur Wilayah.pdf" },
    { "title": "06_Polycentric Urban Region", "pdfPath": "/pdf/4/Perwil/06_Polycentric Urban Region.pdf" },
    { "title": "07_Perwil_23B_Indeks Sentralitas (UTS)", "pdfPath": "/pdf/4/Perwil/07_Perwil_23B_Indeks Sentralitas (UTS).pdf" },
    { "title": "09_Perwil_23B_Natural Based Development", "pdfPath": "/pdf/4/Perwil/09_Perwil_23B_Natural Based Development.pdf" },
    { "title": "10_20240418 BASIS EKONOMI DAN SEKTOR atau KOMODITAS UNGGULAN", "pdfPath": "/pdf/4/Perwil/10_20240418 BASIS EKONOMI DAN SEKTOR atau KOMODITAS UNGGULAN.pdf" },
    { "title": "11_Backward-Forward Linkage in Agriculture -PAR 2023", "pdfPath": "/pdf/4/Perwil/11_Backward-Forward Linkage in Agriculture -PAR 2023.pdf" },
    { "title": "12_20240516 Perencanaan Wilayah BERBASIS INFRASTRUKTUR", "pdfPath": "/pdf/4/Perwil/12_20240516 Perencanaan Wilayah BERBASIS INFRASTRUKTUR.pdf" },
    { "title": "13_Regional Specialization_PAR 2023", "pdfPath": "/pdf/4/Perwil/13_Regional Specialization_PAR 2023.pdf" },
    { "title": "14_Perwil_23B_Regional Collaboration", "pdfPath": "/pdf/4/Perwil/14_Perwil_23B_Regional Collaboration.pdf" },
    { "title": "Regional Planning Glasson and Marshall, 2007", "pdfPath": "/pdf/4/Perwil/Regional Planning Glasson and Marshall, 2007.pdf" }
],



};

const CourseContainer = styled.div`
  background: #333;
  border-radius: 10px;
  margin: 20px;
  padding: 20px;
  width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const CourseTitle = styled.h3`
  margin-bottom: 15px;
  color: #fff;
`;

const MaterialList = styled.div`
  display: flex;
  flex-direction: column;
`;

const CourseImage = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
`;

function Course({ name }) {
  const [showMaterials, setShowMaterials] = useState(false);
  const courseMaterials = materials[name] || [];

  const handleClick = () => {
    setShowMaterials(!showMaterials);
  };

  return (
    <CourseContainer onClick={handleClick}>
      <CourseTitle>{name}</CourseTitle>
      {showMaterials && (
        <MaterialList>
          {courseMaterials.map((material, index) => (
            <Material key={index} title={material.title} pdfPath={material.pdfPath} />
          ))}
        </MaterialList>
      )}
      <CourseImage src="https://uns.ac.id/id/wp-content/uploads/2023/06/logo-uns-biru-1.png" alt="Course logo" />
    </CourseContainer>
  );
}

export default Course;